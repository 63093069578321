import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import Lottie from "lottie-react";
import spinningWheel from "../assets/spinningWheel.json";

import serviceImage1 from "../assets/serviceImage1.jpg"; 
import serviceImage2 from "../assets/serviceImage2.jpg";
import serviceImage3 from "../assets/serviceImage3.jpg";
import serviceImage4 from "../assets/serviceImage4.jpg";


import "./Services.css";

gsap.registerPlugin(ScrollTrigger);

export default function Services() {
  const { t } = useTranslation();
  const component = useRef();
  const slider = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let panels = gsap.utils.toArray(".panel");

      let tl = gsap.timeline({
        scrollTrigger: {
            trigger: slider.current,
            pin: true,
            scrub: 5,
            start: "top top",
            end: () => slider.current.scrollWidth + window.innerWidth,
            anticipatePin: 1,
            markers: false,
        }
      });

      tl.to(slider.current, {
        x: () => -(850 * panels.length - window.innerWidth),
        ease: "none",
        duration: panels.length,
      });
    }, component);
    return () => ctx.revert();
  }, []);

  return (
    <div className="App" ref={component}>
      <div className="firstContainer">
        <div className="container-lg my-services d-flex justify-content-center flex-column">
            <div className="d-flex align-items-center">
                <div className="lottie-container">
                    <Lottie animationData={spinningWheel} className="spinning-wheel" />
                </div>
                <h2 className="ml-2">{t('services.header')}</h2>
            </div>
            <p>
              {t('services.intro')}
            </p>
        </div>
      </div>
      <div ref={slider} className="gsap-container">
        <div className="gradient-line"></div>
        <div className="panel p-service">
            <h4>{t('services.sections.masterTheRoads.title')}</h4>
            <p>
              {t('services.sections.masterTheRoads.description')}
            </p>
            <div>
                <img src={serviceImage1} alt="Service 1" />
            </div>
        </div>
        <div className="panel p-service">
            <h4>{t('services.sections.conquerTheTrack.title')}</h4>
            <p>
              {t('services.sections.conquerTheTrack.description')}
            </p>
            <div>
                <img src={serviceImage2} alt="Service 2" />
            </div>
        </div>
        <div className="panel p-service">
            <h4>{t('services.sections.understandTheMachine.title')}</h4>
            <p>
              {t('services.sections.understandTheMachine.description')}
            </p>
            <div>
                <img src={serviceImage3} alt="Service 3" />
            </div>
        </div>
        <div className="panel p-service">
            <h4>{t('services.sections.driveBeyondLimits.title')}</h4>
            <p>
              {t('services.sections.driveBeyondLimits.description')}
            </p>
            <div>
                <img src={serviceImage4} alt="Service 4" />
            </div>
        </div>        
      </div>
    </div>

  );
}
